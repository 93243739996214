import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

class LegalPage extends React.Component {
  render() {
    return (
      <Layout>
        <SEO title="Legal" />
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut tellus
          elementum sagittis vitae et leo duis. Dolor purus non enim praesent.
          Lacus laoreet non curabitur gravida arcu ac. Vulputate ut pharetra sit
          amet aliquam id diam maecenas ultricies. Sem nulla pharetra diam sit
          amet nisl suscipit adipiscing. Tristique senectus et netus et
          malesuada fames. Laoreet id donec ultrices tincidunt arcu non sodales
          neque. Dignissim cras tincidunt lobortis feugiat vivamus at augue
          eget. Tellus cras adipiscing enim eu turpis. Mattis aliquam faucibus
          purus in. Vel eros donec ac odio tempor orci dapibus. Amet volutpat
          consequat mauris nunc. Viverra vitae congue eu consequat ac. Hendrerit
          dolor magna eget est.
        </p>
        <p>
          Nunc aliquet bibendum enim facilisis gravida neque. Tristique risus
          nec feugiat in. Eget aliquet nibh praesent tristique. Lectus urna duis
          convallis convallis. Nec sagittis aliquam malesuada bibendum arcu.
          Erat pellentesque adipiscing commodo elit at imperdiet dui accumsan.
          In mollis nunc sed id semper risus in hendrerit. Vitae nunc sed velit
          dignissim sodales ut eu sem. Cras sed felis eget velit aliquet
          sagittis id consectetur. Adipiscing bibendum est ultricies integer
          quis auctor elit sed. Vulputate eu scelerisque felis imperdiet proin
          fermentum leo vel. Pretium viverra suspendisse potenti nullam ac
          tortor vitae. Nulla facilisi cras fermentum odio eu feugiat pretium.
          Arcu ac tortor dignissim convallis. Eget gravida cum sociis natoque.
        </p>
        <p>
          Ac placerat vestibulum lectus mauris ultrices eros in cursus turpis.
          Feugiat in fermentum posuere urna nec tincidunt praesent semper. Nec
          sagittis aliquam malesuada bibendum arcu vitae. Orci dapibus ultrices
          in iaculis nunc sed augue lacus. Sodales neque sodales ut etiam sit
          amet nisl. Cursus turpis massa tincidunt dui ut ornare. Diam volutpat
          commodo sed egestas. Tellus elementum sagittis vitae et leo. Etiam sit
          amet nisl purus in mollis nunc sed. Dictumst quisque sagittis purus
          sit amet.
        </p>
      </Layout>
    )
  }
}

export default LegalPage
